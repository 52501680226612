.preview-question-main {
    margin-bottom: 50px;
}

.preview-question {
    min-height: 50px;
    padding: 5px;
    text-align: justify
}

    .preview-question .preview-heading {
        overflow: auto;
        padding: 5px;
    }

    .preview-question.preview-summary {
        margin-top: 20px;
    }

.preview-height50 {
    height: 50px;
}

.preview-padding-bottom10 {
    padding-bottom: 10px;
}

.preview-mouse-hover:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

.header {
    padding: 0 1.875em;
    position: fixed;
    z-index: 1030;
    background-color: #fff;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .header {
        padding: 0 0.65em;
        border-bottom: 1px solid rgba(164,164,164,0.3);
    }

    .mobile {
        box-shadow: none !important;
        border: none !important;
    }

    .content {
        margin-top: 60px;
    }
}

@media only screen and (max-width: 576px) {
    .preview-hide-mobile {
        display: none;
    }

    .preview-question {
        padding-left: 0px;
        padding-right: 0px;
    }

    .preview-heading {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .preview-col-normal {
        text-align: left !important;
    }

    .preview-row-margin {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media only screen and (min-width: 577px) {
    .preview-show-mobile {
        display: none;
    }

    .preview-col-normal {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}
