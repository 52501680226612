@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
/* main */
body {
    display: table;
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px;
    width: 100%;
}

.hide-desktop {
    display: none !important;
}

a {
    text-decoration: none;
    outline: none !important;
    color: #878787;
    transition: all 0.25s ease;
    cursor: pointer;
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    line-height: 42px;
}

.nav-item {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    cursor: pointer;
}

.nav-link {
    background: none !important;
    color: #607d8b;
    display: inline-block;
    font-size: 14px;
    line-height: 26px;
    padding: 10px 0;
    position: relative;
    width: 100%;
}

.header-title {
    padding-left: 15px;
    color: black;
    font-weight: bold;
    font-size: medium;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
}

/* left panel */
.left-panel {
    background: #fff;
    height: 100vh;
    padding: 0;
    vertical-align: top;
    width: 280px;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    position: fixed;
    left: 0;
    bottom: 0;
    top: 55px;
    z-index: 999;
}

    .left-panel .nav-item .nav-link > svg {
        margin-right: 20px;
        width: 20px;
    }

    .left-panel .nav-item:hover {
        background-color: ghostwhite !important;
    }

    .left-panel .sub-menu {
        padding-left: 50px;
    }

    .left-panel .sub2-menu {
        padding-left: 80px;
    }

    .left-panel .main-menu {
        float: left;
        padding: 0;
        padding-bottom: 50px;
    }

    .left-panel .menu-icon {
        color: #607d8b;
        float: left;
        margin-top: 8px;
        text-align: left;
        z-index: 9;
        margin-right: 37px;
    }

    .left-panel .flex-column {
        flex-direction: row !important;
    }

    .left-panel .nav-link.sub-menu-main::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }

    .left-panel .active.nav-item, .active > a > svg.menu-icon {
        background: #fafafa !important;
        color: #febc0d !important;
    }

    .left-panel .active > a {
        color: #febc0d;
        font-weight: bold;
    }

/* right panel */
.right-panel {
    background: #f1f2f7;
    margin-left: 280px;
    margin-top: 55px;
}

    .right-panel .menutoggle {
        padding-top: 7px;
    }

    .right-panel .header {
        background: #f1efef;
        border-bottom: 1px solid #e8e9ed;
        box-shadow: none;
        clear: both;
        padding: 0 21px;
        padding-right: 10px;
        height: 55px;
        position: fixed;
        left: 280px;
        left: 0;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .right-panel .grid-filter label {
        margin-bottom: 0.1rem;
    }

    .right-panel .badge {
        margin-left: 4px;
        background-color: #FFB71C;
    }


    .right-panel .nav-item {
        padding-left: 0;
        padding-left: initial;
        padding-right: 0;
        padding-right: initial;
        width: auto;
        width: initial;
    }

    .right-panel .nav-link {
        padding: 0.5rem 1rem;
    }

/* top left*/
.top-left {
    width: 280px;
    float: left;
    z-index: 1100;
}

    .top-left .menutoggle {
        background-color: transparent;
        color: #99abb4 !important;
        cursor: pointer;
        font-size: 1.5em;
        height: 40px;
        line-height: 40px;
        display: block;
        text-align: right;
    }

/* top center */
.top-center {
    position: fixed;
    top: 0;
    text-align: center;
    width: calc(100% - 108px);
    font-size: medium;
    line-height: 55px;
    font-weight: bold;
}

/* top right */
.top-right {
}

    .top-right nav.bg-light {
        background-color: transparent !important;
    }

    .top-right button.navbar-toggler {
        border-color: transparent;
        line-height: 40px;
        font-size: inherit;
        padding-right: 0px;
        outline: none;
    }

    .top-right .navbar-toggler-icon {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABnklEQVRIie1XS0sCURg9d0aNNJHKZYvoF/QPWgQREVSbluVCZ12khL34sCgio9bWoseuTUHSLnDXv5AWLXswiJZjd24LZ2Fq4DdhtvAsz/nuOXOZ776ADkG4GRTZSM8q2PNVA+3qbCd+0/bgyObBEoCjOnr5bDtx3LZgItIeZeAVQKhOMof14gAR2a16aZzgfNnX3yQUAEKO1jJYwRd7ay+AyjcqKl/V2hQMAEroBoBCDVVwOBZcdXWU9ocqUp8CAK8u705p9cmNTxf/GwaR3yDyux3Pbi6D0mFLqlMA0w6V9ekimqH4M8fHww22pH0CiJkaasaStgIwx/Fhzdgg8pdlwBSNHyzlO0KX6USxVS/2BvITevuKilPPCs4QlQRUtlFRtxmiEseL/Y99uhazpBL41lxajOvjGr9dTl38GTp2LLKDF7cOx4WyrwEEHaqghDZ3nlq55/iwNxChZKYmFACCDsfz4RQvJHcHNY+36WFgf1bCnHsXa8YjPdYbALOJZDpay9A5xblcTo2OTXwAmKyTksep9QeOV8eeMB3DF7atkpkCBHAtAAAAAElFTkSuQmCC");
    }

    .top-right div.navbar-collapse {
        margin-top: 10px;
    }

    .top-right li.nav-item {
        padding: 0px;
        margin: auto;
        width: 20px;
    }

    .top-right a.nav-link {
        margin-left: 5px;
        margin-right: 5px;
    }

    .top-right svg.menu-icon {
        margin: 0;
    }

    .top-right .navbar {
        padding: 0
    }


/* container */
.content {
    float: left;
    padding: 1.875em;
    width: 100%;
    max-width: 100%;
    margin-top: 40px;
}

    .content .card {
        border-radius: 5px;
        padding: 0;
        border: 0px solid transparent;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0,0,0,.125);
        border-radius: .25rem;
    }

        .content .card .card-body {
            float: left;
            padding: 1.25em;
            position: relative;
            width: 100%;
            padding-top: 1vh;
            flex: 1 1 auto;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
        }

            .content .card .card-body .container {
                margin-left: 0px;
                margin-right: 0px;
                max-width: 100%;
            }

@media (max-width: 991px) {
    .hide-mobile {
        display: none !important;
    }

    .hide-desktop {
        display: inline-block !important;
    }

    .right-panel {
        margin-left: 0px;
    }

    .top-right div.navbar-collapse {
        background-color: #fbfbfb !important;
        border: 0.5px solid #ececec;
        margin-top: 0;
    }

    .top-left {
        width: 40px;
    }
}

@media (max-width: 768px) {
    .content.container {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

select {
    padding: 0;
}


.CardClassImage .card {
    height: 320px;
}

.CardClassImage .card-body {
    text-align: center;
}

.image-upload > input {
    display: none;
}

table > tbody > tr:hover {
    background-color: #f8f8ff;
}

.box {
    position: fixed;
    top: -200px;
    left: 30%;
    right: 30%;
    background-color: #fff;
    color: #7F7F7F;
    padding: 20px;
    border: 2px solid #ccc;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -khtml-border-radius: 20px;
    -moz-box-shadow: 0 1px 5px #333;
    -webkit-box-shadow: 0 1px 5px #333;
    z-index: 101;
}

.boxclose {
    float: right;
    margin-top: -14px;
    margin-right: -11px;
    cursor: pointer;
    border: 1px solid #AEAEAE;
    border-radius: 30px;
    background: #dc3545;
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    line-height: 0px;
    color: white !important;
    text-decoration: none !important;
}

.boxclose2 {
    float: right;
    margin-top: -6px;
    margin-right: -11px;
    cursor: pointer;
    border: 1px solid #AEAEAE;
    border-radius: 30px;
    background: #dc3545;
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    line-height: 0px;
    color: white !important;
    text-decoration: none !important;
}

.content .card .card-body {
    float: left;
    padding: 1.25em;
    position: relative;
    width: 100%;
    padding-top: 1vh;
    flex: 1 1 auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}




/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.modal-body .row {
    margin-bottom: 8px;
}

table.table {
    white-space: nowrap;
}

.react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
}

.react-time-picker__inputGroup__input react-time-picker__inputGroup__hour{

    border-color:black;
    border-width: 2px;
}


.weekday-separator {
    border: 1px solid #ddd !important;
    text-align:center;
    font-size: 12px;
}

.weekday-separator2 {
    border-right: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;
    text-align: center;
    min-width: 45px;
    font-size: 12px;
}


.questDiv {
    margin: 20px 5px;
    padding: 5px;
}

    .questDiv a {
        color: #212529;
    }

.questGroupRow {
    padding: 8px;
    color: white;
    background-color: green;
    border-radius: 0.25rem;
}

.questQuestionRow {
    padding: 8px 8px 8px 20px;
}

.questButtonQuestionRow {
    margin: auto;
    text-align: center;
    padding: 0px 0 5px 0;
}


.UniformsButtonNotSelected {
    color: #fff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    background-color: #6c757d;
    border: 1px solid #6c757d;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.UniformsButtonSelected {
    color: #fff;
    background-color: #6c757d;
}



.preview-question-main {
    margin-bottom: 50px;
}

.preview-question {
    min-height: 50px;
    padding: 5px;
    text-align: justify
}

    .preview-question .preview-heading {
        overflow: auto;
        padding: 5px;
    }

    .preview-question.preview-summary {
        margin-top: 20px;
    }

.preview-height50 {
    height: 50px;
}

.preview-padding-bottom10 {
    padding-bottom: 10px;
}

.preview-mouse-hover:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

.header {
    padding: 0 1.875em;
    position: fixed;
    z-index: 1030;
    background-color: #fff;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .header {
        padding: 0 0.65em;
        border-bottom: 1px solid rgba(164,164,164,0.3);
    }

    .mobile {
        box-shadow: none !important;
        border: none !important;
    }

    .content {
        margin-top: 60px;
    }
}

@media only screen and (max-width: 576px) {
    .preview-hide-mobile {
        display: none;
    }

    .preview-question {
        padding-left: 0px;
        padding-right: 0px;
    }

    .preview-heading {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .preview-col-normal {
        text-align: left !important;
    }

    .preview-row-margin {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media only screen and (min-width: 577px) {
    .preview-show-mobile {
        display: none;
    }

    .preview-col-normal {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}

