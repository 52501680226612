/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.modal-body .row {
    margin-bottom: 8px;
}

table.table {
    white-space: nowrap;
}

.react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
}

.react-time-picker__inputGroup__input react-time-picker__inputGroup__hour{

    border-color:black;
    border-width: 2px;
}


.weekday-separator {
    border: 1px solid #ddd !important;
    text-align:center;
    font-size: 12px;
}

.weekday-separator2 {
    border-right: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;
    text-align: center;
    min-width: 45px;
    font-size: 12px;
}


.questDiv {
    margin: 20px 5px;
    padding: 5px;
}

    .questDiv a {
        color: #212529;
    }

.questGroupRow {
    padding: 8px;
    color: white;
    background-color: green;
    border-radius: 0.25rem;
}

.questQuestionRow {
    padding: 8px 8px 8px 20px;
}

.questButtonQuestionRow {
    margin: auto;
    text-align: center;
    padding: 0px 0 5px 0;
}


.UniformsButtonNotSelected {
    color: #fff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: #6c757d;
    border: 1px solid #6c757d;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.UniformsButtonSelected {
    color: #fff;
    background-color: #6c757d;
}


